var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stack-detail-history"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{staticClass:"card"},[_c('DataTable',{attrs:{"value":_vm.newQuotasConfig,"loading":_vm.loading,"row-hover":true,"edit-mode":"row","body-style":{ 'text-align': 'center' },"data-key":"label","editing-rows":_vm.editingRows,"responsive-layout":"scroll"},on:{"update:editingRows":function($event){_vm.editingRows=$event},"update:editing-rows":function($event){_vm.editingRows=$event},"row-edit-save":_vm.onRowEditSave},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" Данные загружаются, пожалуйста подождите... ")]},proxy:true}])},[_c('Column',{attrs:{"field":"label","header":_vm.$t('label'),"styles":{ width: '40%' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.label)+", "+_vm._s(slotProps.data.measure)+" ")]}}])}),_c('Column',{attrs:{"field":"use","header":_vm.$t('use'),"styles":{ width: '7%' },"body-style":{ 'text-align': 'center' }}}),_c('Column',{attrs:{"field":"quota","styles":{ width: '20%' }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.$t('quota')))]),_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                autoHide: false,
                content: _vm.text,
                placement: 'top-start',
                container: 'body',
              }),expression:"{\n                autoHide: false,\n                content: text,\n                placement: 'top-start',\n                container: 'body',\n              }"}],staticClass:"amount-block__hint",attrs:{"icon":"help","size":"medium","color":"del","tabindex":"-1"}})]},proxy:true},{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.quota)+" из "+_vm._s(slotProps.data.max)+" ")]}},{key:"editor",fn:function({ data, field }){return [_c('InputNumber',{attrs:{"show-buttons":"","mode":"decimal","suffix":data.measure,"min":data.use,"max":data.max,"placeholder":"Small","button-layout":"horizontal","input-style":{ 'text-align': 'center', width: '2rem' },"decrement-button-class":"p-button-blue","increment-button-class":"p-button-blue","increment-button-icon":"pi pi-plus","decrement-button-icon":"pi pi-minus","aria-describedby":"value2-help"},model:{value:(data[field]),callback:function ($$v) {_vm.$set(data, field, $$v)},expression:"data[field]"}}),_vm._v(" из "+_vm._s(data.max)+" ")]}}])}),_c('Column',{attrs:{"row-editor":true,"styles":{ width: '6%', 'min-width': '6rem' },"body-style":{ 'text-align': 'center' },"header-style":{ 'text-align': 'center' }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('plain-button',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.setMaxQuota()}}},[_vm._v(" "+_vm._s(_vm.$t('setQuota'))+" ")]),_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                autoHide: false,
                content: _vm.$t('maxQuota'),
                placement: 'top-start',
                container: 'body',
              }),expression:"{\n                autoHide: false,\n                content: $t('maxQuota'),\n                placement: 'top-start',\n                container: 'body',\n              }"}],staticClass:"amount-block__hint",attrs:{"icon":"help","size":"medium","color":"dim","tabindex":"-1"}})]},proxy:true}])})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }