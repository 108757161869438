<template>
  <div class="stack-detail-history">
    <transition name="fade" mode="out-in">
      <div class="card">
        <!--        {{ hasChanges }}-->
        <DataTable
          :value="newQuotasConfig"
          :loading="loading"
          :row-hover="true"
          edit-mode="row"
          :body-style="{ 'text-align': 'center' }"
          data-key="label"
          :editing-rows.sync="editingRows"
          responsive-layout="scroll"
          @row-edit-save="onRowEditSave"
        >
          <template #loading> Данные загружаются, пожалуйста подождите... </template>
          <Column field="label" :header="$t('label')" :styles="{ width: '40%' }">
            <template #body="slotProps">
              {{ slotProps.data.label }}, {{ slotProps.data.measure }}
            </template></Column
          >
          <Column
            field="use"
            :header="$t('use')"
            :styles="{ width: '7%' }"
            :body-style="{ 'text-align': 'center' }"
          >
          </Column>
          <!--          <Column field="quota" :header="$t('quota')" :styles="{ width: '20%' }">-->
          <Column field="quota" :styles="{ width: '20%' }">
            <template #header>
              <div>{{ $t('quota') }}</div>
              <plain-button
                v-tooltip="{
                  autoHide: false,
                  content: text,
                  placement: 'top-start',
                  container: 'body',
                }"
                icon="help"
                size="medium"
                color="del"
                tabindex="-1"
                class="amount-block__hint"
              />
            </template>
            <template #body="slotProps">
              {{ slotProps.data.quota }} из {{ slotProps.data.max }}
            </template>
            <template #editor="{ data, field }">
              <InputNumber
                v-model="data[field]"
                show-buttons
                mode="decimal"
                :suffix="data.measure"
                :min="data.use"
                :max="data.max"
                placeholder="Small"
                button-layout="horizontal"
                :input-style="{ 'text-align': 'center', width: '2rem' }"
                decrement-button-class="p-button-blue"
                increment-button-class="p-button-blue"
                increment-button-icon="pi pi-plus"
                decrement-button-icon="pi pi-minus"
                aria-describedby="value2-help"
              />
              из {{ data.max }}
            </template>
          </Column>
          <Column
            :row-editor="true"
            :styles="{ width: '6%', 'min-width': '6rem' }"
            :body-style="{ 'text-align': 'center' }"
            :header-style="{ 'text-align': 'center' }"
          >
            <template #header>
              <!--                :disabled="!hasChanges || isQuotaEdit"-->
              <plain-button color="primary" @click="setMaxQuota()">
                {{ $t('setQuota') }}
              </plain-button>
              <plain-button
                v-tooltip="{
                  autoHide: false,
                  content: $t('maxQuota'),
                  placement: 'top-start',
                  container: 'body',
                }"
                icon="help"
                size="medium"
                color="dim"
                tabindex="-1"
                class="amount-block__hint"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </transition>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { StackTariff } from '@/models/BillMgr/StackTariff';
import InputNumber from 'primevue/inputnumber';
import showErrorModal from '@/mixins/showErrorModal';
import { isEqual } from 'lodash';
export default {
  name: 'StackQuotas',
  components: {
    InputNumber,
    DataTable,
    Column,
  },
  mixins: [showErrorModal],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof StackTariff,
    },
  },
  data() {
    return {
      // isQuotaEdit: false,
      loading: false,
      editingRows: [],
      value: 5,
      compute: true,
      config: {},
      newquota: {},
      newQuotasConfig: [],
      sort: {
        prop: 'changedate',
        order: 'desc',
      },
    };
  },
  computed: {
    text() {
      return `Квота — это ограничение на создание ресурса, которое вы можете установить в проекте.<br /><br />

Квоты выделяются из лимитов — максимального числа ресурсов, доступных в проекте. Чтобы увеличить лимиты, свяжитесь со <a href="${localStorage.getItem(
        'urlHash'
      )}support/tickets">службой поддержки</a>. `;
    },
    quotaLimit() {
      return this.$store.state.moduleStack.quota_limits;
    },
    isLoading() {
      return this.$store.state.moduleStack.serverRequest;
    },
    quotaServer() {
      return this.$store.state.moduleStack.quotasServer;
    },
    quotaNetwork() {
      return this.$store.state.moduleStack.quotasNetwork;
    },
    quotaVolume() {
      return this.$store.state.moduleStack.quotasVolume;
    },
    project() {
      return this.$store.getters['moduleStack/project_id'];
    },
    networksPublic() {
      return this.$store.state.moduleStack.networks
        .filter(x => x['router:external'] === false)
        .filter(x => x.shared === true)
        .map(x => x.id);
    },
    currentQuota() {
      const quota = {};
      this.newQuotasConfig.map(item => {
        quota[item.name] = item.name === 'ram' ? +item.quota * 1024 : +item.quota;
      });
      return quota;
    },
    // hasChanges() {
    //   // console.log();
    //   const quota = {};
    //   this.newQuotasConfig.map(item => {
    //     quota[item.name] = item.name === 'ram' ? +item.quota * 1024 : +item.quota;
    //     // return
    //   });
    //   // console.log(quota);
    //   const numericData = Object.fromEntries(
    //     Object.entries(this.quotaLimit).map(([key, value]) => [key, Number(value)])
    //   );
    //   delete numericData['rbac_policy'];
    //   // console.log(numericData);
    //   // console.log(!isEqual(quota, numericData));
    //   // return quota;
    //   return !isEqual(quota, numericData);
    // },
    // disabledButton() {
    //   return this.isQuotaEdit || !this.hasChanges;
    // },
    subnetPublic() {
      return this.$store.state.moduleStack.subnets.filter(x =>
        this.networksPublic.includes(x.network_id)
      );
    },
  },
  watch: {
    // newQuotasConfig: {
    //   handler: function (newVal, oldVal) {
    //     console.log('newQuotasConfig', newVal, oldVal);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    // project(event) {
    // if (event) {
    //   console.log(event);
    //   this.quotas();
    //   this.configurator();
    // }
    // },
  },
  mounted() {
    this.init();
  },
  methods: {
    async onRowEditSave(event) {
      let { newData, index } = event;
      // console.log(newData, index);

      this.newQuotasConfig[index] = newData;
      this.newquota = Object.assign(this.newquota, {
        [event.newData.name]:
          event.newData.name === 'ram' ? event.newData.quota * 1024 : event.newData.quota,
      });
      // (this.isQuotaEdit = true),
      await this.sendNewQuota();
    },
    async init() {
      this.loading = true;
      // console.log(this.compute);
      this.$store
        .dispatch('moduleStack/fetchOpenStackRequest', 'projects')
        .then(async () => {
          await this.getQuotas();
          // console.log('1');
        })
        .then(async () => {
          // console.log('2');
          await this.quotas();
        })
        .then(async () => {
          // console.log('3');
          if (
            Object.keys(this.quotaVolume).length === 0 ||
            Object.keys(this.quotaNetwork).length === 0 ||
            Object.keys(this.quotaServer).length === 0
          ) {
            // console.log('-----');
            await this.getQuotas();
          }
          // console.log(this.config);
          if (Object.keys(this.config).length === 0) {
            await this.quotas();
          }
          setTimeout(() => {
            this.configurator();
          }, 3000);
          // await this.configurator();
        });
      // .then(() => (this.loading = false));
    },
    async getQuotas() {
      await this.$store.dispatch('moduleStack/fetchQuotasServer', this.project);
      await this.$store.dispatch('moduleStack/fetchQuotasNetwork', this.project);
      await this.$store.dispatch('moduleStack/fetchQuotasVolume', this.project);
      await this.$store.dispatch('moduleStack/getQuotas', this.tariff.id);
    },
    quotas() {
      const summaryQuotas = {};
      const obj = Object.assign(this.quotaNetwork, this.quotaServer);
      Object.keys(obj).forEach(x => {
        Object.keys(this.quotaLimit).forEach(quota => {
          if (quota === x) {
            summaryQuotas[x] = {
              min: obj[x].in_use || obj[x].used || 0,
              value: obj[x].limit,
              max: +this.quotaLimit[x],
            };
          }
        });
      });
      Object.keys(this.quotaLimit).forEach(quota => {
        Object.keys(this.quotaVolume).forEach(volume => {
          if (volume.toLowerCase().includes(quota)) {
            if (volume.toLowerCase() === `total${quota}used`) {
              summaryQuotas[quota] = {
                min: +this.quotaVolume[volume] || 0,
                max: +this.quotaLimit[quota],
              };
            } else if (volume.toLowerCase() === `maxtotal${quota}`) {
              // console.log(quota, this.quotaVolume[volume]);

              summaryQuotas[quota] = {
                value: this.quotaVolume[volume],
              };
            } else if (volume.toLowerCase() === `maxtotalvolume${quota}`) {
              // console.log(quota, this.quotaVolume[volume]);
              // console.log(this.quotaVolume[volume]);
              summaryQuotas[quota] = {
                value: this.quotaVolume[volume],
              };
            }
          }
        });
      });
      summaryQuotas.volumes.value = this.quotaVolume.maxTotalVolumes;
      summaryQuotas.snapshots.value = this.quotaVolume.maxTotalSnapshots;
      summaryQuotas.gigabytes.value = this.quotaVolume.maxTotalVolumeGigabytes;
      summaryQuotas.rbac_policy.min = this.subnetPublic.length ? this.subnetPublic.length : 0;
      // this.$store.state.moduleStack.networks.filter(x => x.shared === true).length > 0
      //   ? this.$store.state.moduleStack.networks.filter(x => x.shared === true).length
      //   : 0;
      this.config = Object.assign(this.config, summaryQuotas);
      this.compute = false;
    },
    configurator() {
      const newQuotasConfig = [];
      Object.keys(this.config)
        .filter(x => x !== 'rbac_policy') //TODO убрать как починят
        .forEach(config => {
          newQuotasConfig.push({
            name: config,
            edit: false,
            text: ' из ',
            measure: [
              'subnet',
              'router',
              'network',
              'floatingip',
              'public_network',
              'cores',
              'instances',
              'snapshots',
              'volumes',
              'security_group_rule',
              'security_group',
            ].includes(config)
              ? 'шт.'
              : 'ГБ',
            quota: config === 'ram' ? this.config[config].value / 1024 : this.config[config].value,
            use: config === 'ram' ? this.config[config].min / 1024 : this.config[config].min,
            max: config === 'ram' ? this.config[config].max / 1024 : this.config[config].max,
            label: this.$t(config),
          });
        });
      // }
      newQuotasConfig.push({
        name: 'public_network',
        measure: 'шт.',
        edit: false,
        text: ' из ',
        label: this.$t('public_network'),
        quota: +this.quotaLimit['public_network'],
        use: this.config.rbac_policy.min,
        max: +this.quotaLimit['public_network'],
      });
      // this.configTotal = configTotal;
      this.newQuotasConfig = newQuotasConfig;
      this.loading = false;
      return newQuotasConfig;
    },
    showSuccess() {
      this.$modals.open({
        name: 'Success',
        // title: this.$t('order.success.title'),
        title: 'Квоты успешно обновлены',
        // text: this.$t('order.success.text'),
        text: 'Обновление квот обычно занимает пару минут',
        footer: {
          centered: true,
          cancel: {
            props: { title: this.$t('good') },
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
    async setMaxQuota() {
      const numericData = Object.fromEntries(
        Object.entries(this.quotaLimit).map(([key, value]) => [key, Number(value)])
      );

      this.newquota = Object.assign({}, numericData);

      this.newQuotasConfig = this.newQuotasConfig.map(item => {
        const newQuota = this.newquota[item.name];
        if (newQuota !== undefined) {
          if (item.name === 'ram') {
            return { ...item, quota: parseInt(newQuota / 1024, 10) };
          } else return { ...item, quota: parseInt(newQuota, 10) };
        }
        return item;
      });

      await this.sendNewQuota(this.newquota);
    },
    async sendNewQuota(payload) {
      this.loading = true;
      const params = {
        id: this.tariff.id,
        quota: payload ? payload : this.newquota,
      };
      await this.$store
        .dispatch('moduleStack/setQuotas', params)
        .catch(e => {
          this.newquota = {};
          this.showError(e.response.data.message);
          // this.showError(e);
        })
        .finally(() => {
          this.loading = false;
          this.newquota = {};
          // this.init();
        });
    },
  },
  beforeRouteLeave(_to, _from, next) {
    this.init();
    next();
  },
};
</script>

<i18n>
{
  "ru": {
    "desc": "Что произошло",
    "network": "Приватные сети",
    "maxQuota": "Сбросить все изменения",
    "setQuota": "Сброс",
    "subnet": "Приватные подсети",
    "router": "Роутеры",
    "floatingip": "Плавающие IP-адреса",
    "security_group_rule": "Правила файрвола",
    "security_group": "Группы файрвола",
    "public_network": "Публичные подсети",
    "cores": "Ядра",
    "instances": "Серверы",
    "ram": "Оперативная память",
    "snapshots": "Снимки диска",
    "volumes": "Сетевые диски",
    "gigabytes": "Объем сетевых дисков",
    "confirm": "Применить",
    "good": "Хорошо",
    "label": "Название ресурса",
    "use": "Используется",
    "quota": "Квота",
    "max": "Максимум"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
&order-btn {
  min-width: 160px;
  margin: 0.5rem;
  width: calc(100% - 1rem);

  +breakpoint(ms-and-up) {
    width: auto;
  }
}
.clouds-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }
}
.stack-detail-history {
  margin-top: 1.5rem;

  +breakpoint(sm-and-up) {
    margin-top: 2.5rem;
  }
  &-btn {
    position: relative;
    font-family: inherit;
    flexyi(center, center);
    box-shadow: none;
    appearance: none;
    cursor: pointer;
    text-decoration: none;
    transition: background $anim-base-duration ease, color $anim-base-duration ease, border-color $anim-base-duration ease, opacity $anim-base-duration ease;

  }

}
</style>
